import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

import { useTranslation } from 'react-i18next';

import auth from '../Auth';
import '../App.css';

const Nav = (props) => {
    const { t, i18n } = useTranslation();

    const [menuOpen, setMenuOpen] = useState(false)
    const [language, setLanguage] = useState("es");

    const logout = () => {
        auth.logout();
        props.history.replace('/');
    };

    const eventTrack = (category, action, label) => {
        // console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });

        trackAmplitude(category, {
            action: action,
            label: label
        });
    }

    const toogleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
    }

    return (
        <nav className="navbar">
            <div className="navbar-header">
                <a className="navbar-brand mobile-hide" href="/">
                    <img src="/logo.webp" alt="Monplaces"></img>
                </a>
                <a className="navbar-brand mobile-display" href="/">
                    <img src="/isotipo.webp" alt="Monplaces"></img>
                </a>
            </div>
            <ul className="nav navbar-nav mobile-hide">
                <li>
                    <a href="/developments" onClick={eventTrack.bind(this, "Navigation Bar", "Developments Link", "Link")}>{t('Navigation.desarrollos')}</a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://desarrollos.monplaces.com/blog" onClick={eventTrack.bind(this, "Navigation Bar", "Blog Link", "Link")}>{t('Navigation.blog')}</a>
                </li>
                {/* <li>
                    <a href="/properties">Propiedades</a>
                </li> */}
                {/* <li>
                    {
                        (auth.isAuthenticated()) ? <a href="/admin/developments/create">Registrar Desarrollo</a> : ''
                    }
                </li> */}
            </ul>
            {menuOpen &&
                <div className="menu">
                    <ul className="nav navbar-nav">
                        <li>
                            <a onClick={() => {
                                toogleMenu();
                                eventTrack("Navigation Bar", "Developments Link", "Link");
                            }} href="/developments">{t('Navigation.desarrollos')}</a>
                        </li>
                        <li>
                            <a onClick={() => {
                                toogleMenu();
                                eventTrack("Navigation Bar", "Blog Link", "Link");
                            }} target="_blank" rel="noopener noreferrer" href="https://desarrollos.monplaces.com/blog">{t('Navigation.blog')}</a>
                        </li>
                        {/* <li>
                            <a onClick={toogleMenu} href="/properties">Propiedades</a>
                        </li> */}
                        {/* <li>
                            {
                                (auth.isAuthenticated()) ? <a href="/admin/developments/create">Registrar Desarrollo</a> : ''
                            }
                        </li> */}
                    </ul>
                </div>
            }

            <div className='nav navbar-nav mobile-display'>
                <li className="navbar-btn">
                    <button className="btn btn-hamburger" onClick={toogleMenu}></button>
                </li>
            </div>

            <ul className="nav navbar-nav navbar-right">
                <li className='navbar-btn mobile-hide'>
                    <a
                        style={{ color: '#f74750', textDecoration: 'underline', fontWeight: 'bold', padding: '10px 0px' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20conocer%20las%20opciones%20de%20crédito%20hipotecario%20por%20medio%20de%20Monplaces...."
                        onClick={eventTrack.bind(this, "Navigation Bar", "Mortage Link", "Link")}>
                        {t('Home.solicitaCreditoHipotecario')}
                    </a>
                </li>
                {!auth.isAuthenticated() &&
                    <li className="navbar-btn">
                        <button className="btn btn-login" style={{ background: 'linear-gradient(148.47deg, #4daab8 -63.04%, #4eacba 203.89%)' }} onClick={() => auth.login(props.location.pathname)}>{t('Buttons.registrarse')}</button>
                    </li>
                }
                <li className="navbar-btn">
                    {
                        (auth.isAuthenticated()) ? (<button className="btn btn-login" onClick={() => logout()}>{t('Buttons.salir')} </button>) : (<button className="btn btn-login" onClick={() => auth.login(props.location.pathname)}>{t('Buttons.ingresar')}</button>)
                    }
                </li>
                <li className="navbar-btn">
                    <button className="btn btn-language" onClick={() => changeLanguage('es')}>
                        <img src="/icons/icons8-mexico-96.png" alt="Español" />
                    </button>
                    <button className="btn btn-language" onClick={() => changeLanguage('en')}>
                        <img src="/icons/icons8-usa-96.png" alt="English" />
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default withRouter(Nav);