import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

import auth from '../../Auth';

import './ReserveButton.css';

const { REACT_APP_MONPLACE_CUSTOMER_DEAL_API } = process.env;

const ReserveButton = ({ page, unit, commercialPolicy, style = {} }) => {
    const history = useHistory();
    const location = useLocation();

    const eventTrack = (category, action, label) => {
        // console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });

        trackAmplitude(category, {
            action: action,
            label: label
        });
    }

    const createDeal = async (user, unit, commercialPolicy) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                contact: {
                    email: user.email,
                    firstName: user.given_name,
                    lastName: user.family_name,
                },
                deal: {
                    transactionType: unit.operationType,
                    product: unit.group.display_name,
                    unit: unit.code,
                    amount: commercialPolicy ? (unit.price - (unit.price * commercialPolicy.discount / 100)) : unit.price
                }
            })
        }

        await fetch(REACT_APP_MONPLACE_CUSTOMER_DEAL_API, requestOptions)
            .then(response => {
                if (response.ok) {
                    console.log("Negocio creado en CRM")
                }
                else {
                    console.log("¡Ocurrió un error!, al crear negocio en CRM")
                }
            })
            .catch(error => console.log(`¡Ocurrió un error!, ${error}`));
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        eventTrack(page, "Reserve Button", "Button")

        if (!auth.isAuthenticated()) {
            return auth.login(location.pathname);
        }

        const userInfo = await auth.getUserInfo();

        await createDeal(userInfo, unit, commercialPolicy);

        if (commercialPolicy) {
            history.push(`/checkout/${unit.id}/policy/${commercialPolicy.id}`);
        } else {
            history.push(`/checkout/${unit.id}`);
        }
    }

    return (
        <button className='btn btn-principal' style={style} onClick={handleSubmit}>
            Reservar
        </button>
        // <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado en reservar la unidad ${unit.code}`} style={style} target='_blank' rel='noopener noreferrer' className='btn btn-principal'>Reservar</a>
    )
}

export default ReserveButton;