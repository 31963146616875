import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { init as initAmplitude } from '@amplitude/analytics-browser';

import i18n from './i18n';
import App from "./App";

import 'tachyons';
import './index.css';

import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';

const { REACT_APP_API_URL, REACT_APP_STRIPE_PUBLISHABLE_KEY, AMPLITUDE_API_KEY } = process.env;

const httpLink = createHttpLink({
  uri: '/',
});

const tokenFlag = 'token';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(tokenFlag);
  // return the headers to the context so httpLink can read them
  return {
    uri: REACT_APP_API_URL,
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const optionsGA = {
  testMode: false
};
ReactGA.initialize('G-SYR83EVEWN', optionsGA);
ReactGA.send({ hitType: "pageview", page: window.location.pathname }); // For tracking page view
// ReactGA.set({
//   // username: localStorage.getItem('userName'),
//   // Other relevant user information
// })

const advancedMatching = { em: 'customer@monplaces.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const optionsPixel = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('925182401504256', advancedMatching, optionsPixel);
ReactPixel.init('681578226354406', advancedMatching, optionsPixel);

ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

initAmplitude('f2b9e48ac72889161f207c68bd8182f2');

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Elements>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerServiceWorker();